import React from 'react'
import {
  BoundedContent,
  Box,
  Column,
  FluidImage,
  GridContainer,
  Row,
  Button,
  Typography,
} from '@vp/swan'

import { SEOContainer } from '@vp/digital-seo-lib-ubik'
import { TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'

import styles, { stylesheet } from './WixDomainsErrorPage.scss'
import { useLogger, useStyles } from '@vp/ubik-context'
import Redirect from '../../common/Redirect'

const BACKGROUND_IMAGE_KEY = 'backgroundImage'
const LOGO_IMAGE_KEY = 'logo'

const WixDomainsErrorPage = (pageContext: any) => {
  useStyles(stylesheet)
  const { genericError, seo } = pageContext.pageData
  const logger = useLogger()

  // Gatsby build doesn't have a window, need to check here
  const VP_HOST = typeof window !== 'undefined' ? window.location?.host : ''

  const sectionPrimaryCallback = () => {
    logger.debug('WixDomainsErrorPage: Clicking Primary CTA (Back)')
    window.history.back()
  }

  const sectionSecondaryCallback = () => {
    logger.debug(`WixDomainsErrorPage: Clicking Secondary CTA (Redirect to ${VP_HOST})`)
    window.location.href = 'https://' + VP_HOST
  }

  const errorPageBackgroundImage = genericError.media?.find(
    ({ key }: { key: string }) => key === BACKGROUND_IMAGE_KEY
  )
  const errorPageLogo = genericError.media?.find(
    ({ key }: { key: string }) => key === LOGO_IMAGE_KEY
  )

  logger.debug('WixDomainsErrorPage: Rendering with pageContext:', pageContext)

  logger.info('WixDomainsErrorPage: Component rendering started.')

  return (
    <Redirect redirectPath={null} isRedirecting={false}>
      <>
        <SEOContainer content={seo} />
        {/* Wix Error hero */}
        {genericError && (
          <Box className={styles.wixErrorPageHeroContainer}>
            <Box className={styles.wixErrorPageHeroContent}>
              <BoundedContent>
                <GridContainer>
                  <Row>
                    <Column span={6} py={8}>
                      {errorPageLogo && (
                        <FluidImage
                          className={styles.wixErrorPageHeroLogo}
                          src={errorPageLogo.mediaUrl}
                          alt={errorPageLogo.altText}
                        />
                      )}
                      {genericError.title && (
                        <Typography
                          m={0}
                          mt='6'
                          component='h1'
                          textColor='standard'
                          darkMode
                          fontSize='x3large'
                        >
                          {genericError.title}
                        </Typography>
                      )}
                      {genericError.description && (
                        <Typography
                          m={0}
                          mt='5'
                          component='p'
                          textColor='standard'
                          darkMode
                        >
                          <TokenReplacer
                            template={genericError.description}
                            replacements={{
                              contactUsPhone:
                                pageContext.careChannels?.phone?.phoneNumber,
                            }}
                            convertMarkdown
                          />
                        </Typography>
                      )}
                      <Box mt='7'>
                        {genericError.features[0] && (
                          <Button
                            mr='6'
                            skin='secondary'
                            onClick={() => {
                              sectionPrimaryCallback()
                            }}
                          >
                            {genericError.features[0].title}
                          </Button>
                        )}
                        {genericError.features[1] && (
                          <Button
                            skin='tertiary'
                            darkMode
                            onClick={() => {
                              sectionSecondaryCallback()
                            }}
                          >
                            {genericError.features[1].title}
                          </Button>
                        )}
                      </Box>
                    </Column>
                  </Row>
                </GridContainer>
              </BoundedContent>
              {errorPageBackgroundImage && (
                <FluidImage
                  className={styles.wixErrorPageHeroBackgroundImage}
                  src={errorPageBackgroundImage.mediaUrl}
                  alt={errorPageBackgroundImage.altText}
                />
              )}
            </Box>
          </Box>
        )}
      </>
    </Redirect>
  )
}

export default WixDomainsErrorPage
